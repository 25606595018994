import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Slider from "react-slick";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();

  //const [isToggleOpen, setToggleOpen] = useState([]);
  //const handleToggle = (e) => {
  //  setToggleOpen({
  //    ...isToggleOpen,
  //    [e.currentTarget.id]: isToggleOpen[e.currentTarget.id] ? false : true,
  //  });
  //};

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Layout location={location} locale={intl.locale} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `Patient Voices: Insight through true stories of recovery`
            : `Patientenberichte: Einblicke in den Therapieerfolg`
        }
        description={
          intl.locale === "en"
            ? `Patients testimonials- remarkable improvements after injuries. Learn stories how life has changed with HAL treatment.`
            : `Erfahrungsberichte - Erstaunliche Verbesserungen nach Verletzungen. Lesen Sie wie die HAL Therapie das Leben verändern kann.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/1w0VtVuhZsng5XiHy1xYJF/c9bfc1236811562182aac62919ac4919/E181_191.jpg?w=1200&h=630&fit=fill"
      />

      <section className="sect sect_TreatmentCvd_first sect-bg-bge pt-5">
        <div className="main_back sect-bg-bge"></div>
        <div className="main_copy_noimage">
          <h1 className="h1">
            {intl.locale === "en"
              ? `Discover real stories from real people about how Cyberdyne
            technology changed their lives forever.`
              : `Entdecken Sie reale Geschichten von Patienten darüber wie Cyberdyne deren Leben für immer verändert hat.`}
          </h1>
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en" ? `Testimonials` : `Erfahrungsberichte`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Patient stories are the story of us. Read patient stories and see
            what care and support they received from Cyberdyne.`
              : `Die Erfahrungsberichte der Patienten sind unsere Geschichte. Lesen Sie die Geschichten der Patienten und entdecken Sie wie Cyberdyne sich um sie gekümmert und ihnen geholfen hat.`}
          </p>
        </div>
      </section>

      <section className="sect bg-bge">
        <div className="container">
          <div className="flex-row bt-1 pt-6 mt-0">
            <div className="flex-column-5">
              <StaticImage
                src="../../images/treatment/testimonials/image-from-rawpixel-id-3012288-original.png"
                className="mb-4 testimonials-image"
                alt=""
                width={200}
              />
              <p className="message-label-bold txt txt-rot mb-4">
                Attila Karginer
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en" ? `Diagnosis` : `Diagnose`}
              </p>
              <p className="mb-2">
                {intl.locale === "en"
                  ? `spinal cord injury`
                  : `Rückenmarksverletzung`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of injury`
                  : `Jahr der Verletzung`}
              </p>
              <p className="mb-2">20XX</p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of treatment start`
                  : `Trainingsstart`}
              </p>
              <p className="mb-2">
                {intl.locale === "en" ? `20XX + 1 month` : `20XX + 1 Monat`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Number of sessions`
                  : `Anzahl der Therapieeinheiten`}
              </p>
              <p>24</p>
            </div>
            <div className="flex-column-7 quote">
              <h2 className="h3">Attillas Story</h2>
              <p>
                {intl.locale === "en"
                  ? `Shortly after my accident and emergency surgery I was shocked. I
  regretted that I did not do many things, such as travelling or
  going to dance with my wife if there was the opportunity. The
  doctors prognosis said that with good training I might be able
  to stand up again. After a few days with full effort to recover
  the controll of my knee and ankle I was motivated and qualified
  to do the Cyberdyne Therapy. My goals were set high but the
  therapist kept them in mind and I got the hardest training of my
  life. It was easy for me to reach my physical limits during
  training, and used the regenerations periods to regain control
  of my body step by step. We are sure that the HAL treament was
  one main point why I am able to do sports and go for work again
  nowadays. I can highly recommended the HAL Therapy to anyone
  that is in the same situation as me.`
                  : `Kurz nach meinem Unfall und der anschließenden Notoperation stand ich unter Schock und habe bereut, viele Dinge nicht getan zu haben, etwa oft zu verreisen oder einfach mal ein bisschen öfter mit meiner Frau zu tanzen, wenn sich die Gelegenheit bietet.
  Die Prognosen der meisten Ärzte gaben im Durchschnitt wieder, dass ich mich bei gutem Training vielleicht wieder aufstellen könnte.
  Nach einigen Tagen voller Anstrengung und dem Wiedererlangen der Kontrolle über Knie und Sprunggelenk habe ich Blut geleckt und mich für die Therapie bei Cyberdyne qualifizieren können.
  Meine sehr hoch gesteckten Ziele wurden von den Therapeuten berücksichtigt, und ich habe das härteste Training meines Lebens erhalten.
  Es war sehr einfach für mich, wieder und wieder an meine körperliche Belastungsgrenze gebracht zu werden, da ich während der Regenrationsphasen immer ein kleines bisschen mehr Kontrolle über meinen Körper zurückgewonnen habe. Wir sind uns sicher, dass das Training im HAL maßgeblich dazu beigetragen hat, dass ich heute wieder meinem Sport und der Arbeit nachgehen kann. Ich kann und werde es jedem weiterempfehlen, der sich in einer ähnlichen Situation befindet.`}
              </p>
            </div>
          </div>
          <div className="flex-row bt-1 pt-6 mt-7">
            <div className="flex-column-5">
              <StaticImage
                src="../../images/treatment/testimonials/FrankReufer_and_son.jpg"
                className="mb-4 testimonials-image"
                alt=""
                width={200}
              />
              <p className="message-label-bold txt txt-rot mb-4">
                Frank Reufer
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en" ? `Diagnosis` : `Diagnose`}
              </p>
              <p className="mb-2">
                {intl.locale === "en"
                  ? `spinal cord injury`
                  : `Rückenmarksverletzung`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of injury`
                  : `Jahr der Verletzung`}
              </p>
              <p className="mb-2">20XX</p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of treatment start`
                  : `Trainingsstart`}
              </p>
              <p className="mb-2">20XX + 2 Monate</p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Number of sessions`
                  : `Anzahl der Therapieeinheiten`}
              </p>
              <p>72</p>
            </div>
            <div className="flex-column-7 quote">
              <h2 className="h3">
                {intl.locale === "en" ? `Franks Story` : `Franks Geschichte`}
              </h2>
              {intl.locale === "en" ? (
                <p>
                  Dear Cyberdyne Team… In 2015 I was allowed to train with you
                  after my accident for a couple of months. My initial diagnosis
                  was that I would need a wheelchair for the rest of my life.
                  Luckily this is not the case 😉. Your work is for sure a big
                  part of this progression.
                  <br />I am thankful for all the people that helped me in this
                  difficult time. In the first weeks Elisabeth took care of me
                  and then Nikta for a longer time. Now and then I Marvin und
                  Jasmin as well. At the end of the therapy Irene took care of
                  me, who is now back at the Bergmannsheil working as a
                  physiotherapist. After finishing my treatment at Cyberdyne I
                  continued my ambulant rehabilitation for almost 12 month and
                  was able to make further progress. 18 months after the
                  accident I was able to start my old profession as a chemical
                  technician. After a while I needed to admit that I wasn’t
                  capable from a physical standpoint to do my old profession. My
                  employer and the Berufsgenosschenschaft helped me to do an
                  occupational retraining as a chemical laboratory assistant
                  within 2 years. Concerning my health, I am doing fine despite
                  some aches and pains which are normal after such an accident.
                  I can walk distances between 8 and 9 km without needing a
                  break. I never believed to be in such a good shape again. As I
                  already said you are not innocent concerning my progression.
                </p>
              ) : (
                <p>
                  Hallo liebes Team von Cyberdyne. Im Jahr 2015 durfte ich nach
                  meinem schweren Unfall mehrere Monate bei euch trainieren.
                  Meine ursprüngliche Diagnose sah so aus, dass ich den Rest
                  meines Lebens wohl im Rollstuhl verbringen würde. Das ist
                  glücklicherweise so nicht eingetreten. Dafür wart ihr mit
                  Sicherheit zu einem großen Teil mitverantwortlich. Mein Dank
                  gilt allen, die mir in dieser schweren Zeit geholfen haben. In
                  den ersten Wochen Elisabeth, danach eine längere Zeit Nikta,
                  zwischendurch auch mal Marvin und Jasmin. Zum Ende meiner
                  Therapie hat sich Irene um mich gekümmert, die ja wieder als
                  Physiotherapeutin zurück ins Bergmannsheil gewechselt ist. Ich
                  habe nach meiner Zeit bei euch noch fast zwölf Monate eine
                  ambulante Reha durchlaufen, durch ich weitere Fortschritte
                  machen konnte.18 Monate nach meinem Unfall bin ich dann wieder
                  in meinen alten Beruf als Chemikant zurückgekehrt.Allerdings
                  musste ich mir nach einiger Zeit eingestehen, dass ich
                  körperlich doch nicht mehr in der Lage war, den alten Beruf
                  weiter auszuüben.Mit Hilfe meines Arbeitgebers und der
                  Berufsgenossenschaft habe ich dann innerhalb von zwei Jahren
                  eine Umschulung zum Chemielaboranten gemacht. Gesundheitlich
                  geht es mir bis auf die üblichen Wehwehchen nach so einem
                  Unfall sehr gut. Strecken von 8–9 Kilometern kann ich ohne
                  Pause laufen. Ich hätte nie gedacht, wieder so fit zu werden.
                  Wie schon gesagt, ihr seid da nicht ganz unschuldig. Siehe
                  auch die Bilder von meinem Familienurlaub 2019 in den
                  bayerischen Alpen.
                </p>
              )}
            </div>
          </div>
          <div className="flex-row bt-1 pt-6 mt-7">
            <div className="flex-column-5">
              <StaticImage
                src="../../images/treatment/testimonials/Robin_Berthold.jpg"
                className="mb-4 testimonials-image"
                alt=""
                width={200}
              />
              <p className="message-label-bold txt txt-rot mb-4">
                Robin Berthold
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en" ? `Diagnosis` : `Diagnose`}
              </p>
              <p className="mb-2">
                {intl.locale === "en"
                  ? `spinal cord injury`
                  : `Rückenmarksverletzung`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of injury`
                  : `Jahr der Verletzung`}
              </p>
              <p className="mb-2">20XX</p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of treatment start`
                  : `Trainingsstart`}
              </p>
              <p className="mb-2">
                {intl.locale === "en" ? `20XX + 2 month` : `20XX + 2 Monate`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Number of sessions`
                  : `Anzahl der Therapieeinheiten`}
              </p>
              <p>72</p>
            </div>
            <div className="flex-column-7 quote">
              <h2 className="h3">
                {intl.locale === "en" ? `Robins Story` : `Robins Geschichte`}
              </h2>
              {intl.locale === "en" ? (
                <p>
                  At first, I want to thank the nice and competent staff of the
                  Cyberdyne Care Team. I was offered an ambulatory Training at
                  Cyberdyne after my accident with an incomplete tetraplegia,
                  which I accepted thankfully. Already after the first session,
                  I knew that this kind of training is useful for people in my
                  situation. I was impressed and thankfully was able to progress
                  quickly. In my opinion my recovery would not be as good as it
                  was without this kind of therapy. Today I can walk without any
                  aids and could leave the difficult time behind me. Cyberdyne
                  played a huge part of this development. So again, a big thank
                  you goes to the whole team. Without you, things would have a
                  different outcome.
                </p>
              ) : (
                <p>
                  Zuerst möchte ich mich ausdrücklich bei den sehr netten und
                  kompetenten Mitarbeitern des Cyberdyne Care Teams bedanken.
                  Mir wurde nach einem Unfall mit folgender inkompletter
                  Tetraplegie ein Gehtraining durch Cyberdyne angeboten, welches
                  ich natürlich dankbar annahm. Schon nach dem ersten Training
                  wurde mir bewusst, wie hilfreich eine solche Therapie für
                  Menschen in meiner Lage sein kann. Ich war begeistert und
                  konnte auch glücklicherweise schnell große Fortschritte
                  erzielen. Ich glaube, dass meine Genesung ohne diese Art von
                  Therapie schlechter verlaufen wäre. Heute kann ich wieder ganz
                  ohne Hilfsmittel laufen und diese schwere Zeit hinter mir
                  lassen. Cyberdyne hat definitiv einen sehr großen Teil dazu
                  beigetragen. Also nochmal ein dickes Danke an das ganze Team.
                  Ohne euch würden die Dinge anders aussehen.
                </p>
              )}
            </div>
          </div>
          <div className="flex-row bt-1 pt-6 mt-7">
            <div className="flex-column-5">
              <StaticImage
                src="../../images/treatment/testimonials/Anderson_Mogollon.jpg"
                className="mb-4 testimonials-image"
                alt=""
                width={200}
              />
              <p className="message-label-bold txt txt-rot mb-4">
                Anderson Mogollon
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en" ? `Diagnosis` : `Diagnose`}
              </p>
              <p className="mb-2">
                {intl.locale === "en"
                  ? `spinal cord injury`
                  : `Rückenmarksverletzung`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of injury`
                  : `Jahr der Verletzung`}
              </p>
              <p className="mb-2">20XX</p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of treatment start`
                  : `Trainingsstart`}
              </p>
              <p className="mb-2">
                {intl.locale === "en" ? `20XX+2 years` : `20XX+2 years`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Number of sessions`
                  : `Anzahl der Therapieeinheiten`}
              </p>
              <p>53</p>
            </div>
            <div className="flex-column-7 quote">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `Andersons Story`
                  : `Andersons Geschichte`}
              </h2>
              {intl.locale === "en"
                ? `I was lifting my legs with my hips while walking and not my
  knees. After 3 months of intense therapy at Cyberdyne in Bochum,
  I started walking by lifting my knees. Now, I can walk for a
  longer period of time because my walking method has improved.
  Before going to Cyberdyne, I was walking 10 meters in almost 4
  minutes but now it takes me around 50 seconds.`
                : `Zunächst habe ich meine Beine gehoben, in dem ich die Hüfte gebeugt und nicht meine Knie gehoben habe. Nach 3 Monaten intensiver Therapie bei Cyberdyne, begann ich beim Gehen auch meine Knie zu heben. Heute kann ich längere Strecken gehen, da sich mein Gangbild verbessert hat. Bevor ich bei Cyberdyne war, habe ich für die Distanz von 10 Metern fast 4 Minuten benötigt, jetzt sind es nur noch ca. 50 Sekunden.`}
            </div>
          </div>
          <div className="flex-row bt-1 pt-6 mt-7">
            <div className="flex-column-5">
              <StaticImage
                src="../../images/treatment/testimonials/Burkhard.jpg"
                className="mb-4 testimonials-image"
                alt=""
                width={200}
              />
              <p className="message-label-bold txt txt-rot mb-4">
                Burkhard Boehmer
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en" ? `Diagnosis` : `Diagnose`}
              </p>
              <p className="mb-2">
                {intl.locale === "en"
                  ? `spinal cord injury`
                  : `Rückenmarksverletzung`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of injury`
                  : `Jahr der Verletzung`}
              </p>
              <p className="mb-2">20XX</p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Year of treatment start`
                  : `Trainingsstart`}
              </p>
              <p className="mb-2">
                {intl.locale === "en" ? `20XX + 1 month` : `20XX + 1 Monat`}
              </p>
              <p className="font-weight-bold mb-0">
                {intl.locale === "en"
                  ? `Number of sessions`
                  : `Anzahl der Therapieeinheiten`}
              </p>
              <p>76</p>
            </div>
            <div className="flex-column-7 quote">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `Burkhards Story`
                  : `Burkhards Geschichte`}
              </h2>
              {intl.locale === "en" ? (
                <p>
                  Without Cyberdyne Care Robotics GmbH, I would be staying in a
                  nursing case nowadays. Thanks to the trainings with the HAL
                  Robot Suit and the competent team from ZNB that I can live a
                  self-determined life again.
                </p>
              ) : (
                <p>
                  Ohne die Cyberdyne Care Robotics GmbH wäre ich heute ein
                  Pflegefall. Dank des Trainings mit dem HAL Robot Suit und den
                  kompetenten Mitarbeitern vom ZNB kann ich heute wieder ein
                  selbstbestimmtes Leben führen.
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2 bg-bls">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en" ? `Video` : `Videos`}
          </p>
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Watch the video to see how Cybernics Technology is applied in
  therapy.`
              : `Schauen Sie sich das Video an und sehen Sie, wie die Cybernics Technologie in der Therapie angewendet wird.`}
          </p>
          <div className="slide">
            <Slider {...settings}>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/mQIC9yaZx4I"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Improvements after 3 months of HAL Training`
                      : `Entwicklung nach 3 Monaten HAL Training`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/auwdmJpxsQM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `After 72 sessions of HAL Training this patient is able to
  walk without any aids.`
                      : `Nach 72 Einheiten kann dieser Patient wieder ohne Hilfsmittel gehen`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/3_T2f2t6CiI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Watch Attilas improvements.`
                      : `Sehen Sie sich die Fortschritte von Attila im Video an.`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/S8amDWmiNZU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Only 17 session of HAL Training were need to get this
  patient back to walking`
                      : `Nur 17 Einheiten HAL Training waren nötig um Robin auf die Beine zu helfen`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/5QeXmD3rjmQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `HAL training helped Burkhard to walk without any aids`
                      : `HAL Training hat dazu geführt, dass Burkhard wieder ohne Hilfsmittel gehen kann`}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 mb-5 txt txt-rot">
            {intl.locale === "en"
              ? `Learn more about HAL Treatment`
              : `Mehr über das HAL Training erfahren`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cerebrovascular disease`
                    : `Zerebrovaskulären Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `From the perspective of motor learning, repetitive movement
  and voluntary exercise are important elements`
                    : `Aus Sicht des motorischen Lernen sind wiederholte Bewegungen und willkürlich durchgeführte Aufgaben wichtige Elemente`}
                </p>
                <Link to="/treatment/cvd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Spinal Cord Injury`
                    : `Rückenmarksverletzungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Locomotor training performed using a neurologically controlled
  cyborg HAL enables the neural activity and repetitive
  excercises of specific tasks.`
                    : `Lokomotionstraining mit dem neurologisch kontrollierten Cyborg HAL ermöglicht neuronale Aktivität und wiederholte Durchführung spezifischer Aufgaben.`}
                </p>
                <Link to="/treatment/spinal/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Progressive neuromuscular diseases`
                    : `Progressive neuromuskuläre Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The 5 years Post-marketing surveillance results suggest that
  treatment with the HAL system shows improvement in gait
  related outcome measures despite the progressive nature of the
  diseases.`
                    : `Beobachtungen 5 Jahre nach Einführung des HALs zeigen, dass die HAL-Therapie zu Verbesserungen in Testungen in Bezug auf die Gehfähigkeit führt, trotz der fortschreitenden Art der Erkrankung.`}
                </p>
                <Link to="/treatment/pnd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `About ZNB - Our Therapist`
                    : `Das ZNB / Unsere Therapeuten`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Meet our team. We offer exceptional level of personalised
  skill and expertise.`
                    : `Treffen Sie unser Team. Wie bieten ein außergewöhnliches Level an individuellen Fähigkeiten und Fachwissen. `}
                </p>
                <Link to="/treatment/znb/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Frequently Asked Questions`
                    : `Häufig gestellte Fragen (FAQ)`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Here are our general frequently asked questions. For more
  specific questions, please contact us directly.`
                    : `Hier finden Sie häufig gestellte Fragen. Für spezifische Fragen kontaktieren Sie uns bitte direkt.`}
                </p>
                <div>
                  <Link to="/treatment/faq/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;
